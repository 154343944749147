<template>
  <v-container id="contact">
    <v-row>
      <v-col class="_custom-font _text-header">
        CONTATO

        <v-divider class="primary" />
      </v-col>
    </v-row>

    <v-row v-if="$vuetify.breakpoint.xs">
      <v-col cols="12">
        <v-card>
          <div style="width: 100%">
            <iframe
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=724,%20Av.%20da%20Saudade%20Amparo,%20State%20of%20S%C3%A3o%20Paulo+()&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              width="100%"
              height="100%"
              frameborder="0"
            >
            </iframe>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-img height="100" :src="logo_lugar" />

          <v-card-title class="_custom-font _text-paragraph">
            SALA 1
          </v-card-title>

          <v-divider class="primary mx-4" />

          <v-card-text class="_custom-font _text-subtitle">
            <div>
              <v-icon class="orange--text">mdi-map-marker</v-icon>
              724 Av. da Saudade, Amparo, São Paulo
            </div>
            <div class="d-flex">
              <v-icon class="orange--text">mdi-phone</v-icon>
              019 99681-5915

              <v-spacer />

              <v-icon class="orange--text">mdi-whatsapp</v-icon>
              019 99681-5915
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="6">
        <v-card>
          <div style="width: 100%; height: 40vh">
            <iframe
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=R.%20Antonio%20Prado,%20Amparo,%20S%C3%A3o%20Paulo%20-%20Pro%20Corpo+(Pro%20Corpo)&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              width="100%"
              height="100%"
              frameborder="0"
            >
            </iframe>
          </div>
        </v-card>
      </v-col>
      <!--  -->
      <v-col cols="6">
        <v-card class="">
          <v-img height="200" :src="logo_lugar" />

          <v-card-title class="_custom-font _text-paragraph">
            SALA 1
          </v-card-title>

          <v-divider class="primary mx-4" />

          <v-card-text class="_custom-font _text-subtitle">
            <div>
              <v-icon class="orange--text">mdi-map-marker</v-icon>
              724 Av. da Saudade, Amparo, São Paulo
            </div>
            <div>
              <v-icon class="orange--text">mdi-phone</v-icon>
              019 99681-5915
            </div>
            <div>
              <v-icon class="orange--text">mdi-whatsapp</v-icon>
              019 99681-5915
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import logo_lugar from "../assets/contact/logo_lugar.jpg";

export default {
  name: "MainContact",
  components: {},
  data: () => ({
    logo_lugar,
  }),
};
</script>
