<template>
  <v-app>
    <v-app-bar prominent app>
      <MainHeader />
    </v-app-bar>

    <v-main class="snap-scroll">
      <FullPageView>
        <MainAbout />
      </FullPageView>

      <FullPageView>
        <MainTechniques />
      </FullPageView>

      <FullPageView>
        <MainContact />
      </FullPageView>

      <FullPageView>
        <MainFooter />
      </FullPageView>
    </v-main>
  </v-app>
</template>

<script>
import MainFooter from "./components/MainFooter.vue";
import MainHeader from "./components/MainHeader.vue";
import MainAbout from "./components/MainAbout.vue";
import MainTechniques from "./components/MainTechniques.vue";
import MainContact from "./components/MainContact.vue";

import FullPageView from "./components/_joker/fullPageView.vue";

export default {
  name: "App",
  components: {
    MainFooter,
    MainHeader,
    MainAbout,
    MainContact,
    MainTechniques,

    FullPageView,
  },
};
</script>

<style>
.snap-scroll {
  position: relative;
  height: 100vh;
  overflow: auto;
  scroll-snap-type: y mandatory;
}

.fixed-width {
  max-width: 1100px;
}

@font-face {
  font-family: caviarDreams;
  src: url(./assets/_fonts/CaviarDreams.ttf);
}

._custom-font {
  font-family: "caviarDreams" !important;
}

._text-header {
  font-size: 3rem;
}

._text-paragraph {
  font-size: 1.25rem;
}

._text-subtitle {
  font-size: 1rem;
}

@media screen and (max-width: 960px) {
  ._text-header {
    font-size: 2rem;
  }

  ._text-paragraph {
    font-size: 1rem;
  }

  ._text-subtitle {
    font-size: 0.875rem;
  }
}
</style>
