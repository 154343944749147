<template>
  <v-container id="about">
    <v-row>
      <v-col class="_custom-font _text-header">
        SOBRE

        <v-divider class="primary" />
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="!$vuetify.breakpoint.xs" sm="3">
        <v-img :src="profile" />
      </v-col>

      <v-col>
        <v-card flat>
          <v-card-text class="text-justify _custom-font _text-paragraph">
            Meu nome é André do Canto e Castro Gaspari, nascido em Amparo-SP.
            <br />
            <br />
            Sou formado em fisioterapia pela UNESP de Marília, e pós-graduado em
            Quiropraxia pela Faculdade Inspirar de Bauru. Em 2017 passei em um
            concurso público e comecei a atuar como fisioterapeuta da cidade de
            Areiópolis-SP, ficando no cargo até 2022.
            <br />
            <br />
            Nesse mesmo período residi na cidade de Botucatu-SP e realizei
            atendimentos particulares na Salus Saúde e Estética na área de
            Quiropraxia e Fisioterapia. No segundo semestre de 2022 me mudei
            para Amparo e passei a atender na Academia Pro Corpo.
            <br />
            <br />
            Desde 2018 atendo como Quiroprata em eventos esportivos de diversas
            áreas, como Crossfit, Beach Tennis, Atletismo e Corrida de Rua.
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        v-if="!$vuetify.breakpoint.xs"
        class="_custom-font _text-paragraph"
      >
        <v-timeline dense>
          <v-timeline-item>
            2012 - Início da graduação em Fisioterapia
          </v-timeline-item>

          <v-timeline-item>
            2016 - Graduado em Fisioterapia pela UNESP de Marília
          </v-timeline-item>

          <v-timeline-item>
            2017 - Fisioterapeuta concursado em Areiópolis-SP
          </v-timeline-item>

          <v-timeline-item>
            2018 - Pós Graduado em Quiropráxia pela Faculdade Inspirar de Bauru
          </v-timeline-item>

          <v-timeline-item>
            2022 - Fisioterapeuta e Quiroprata na Academia Pro Corpo em
            Amparo-SP
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import profile from "../assets/about/profile.jpeg";

export default {
  name: "MainAbout",
  components: {},
  data: () => ({
    profile,
  }),
};
</script>
